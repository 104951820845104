/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Como me gustan las instalaciones lo mas limpias posibles, por eso he decidido hacer pruebas, en un disco duro a parte he instalado el sistema básico de Debian Lenny. Esto lo he hecho con el CD1, despues de instalarse lo basico le he quitado las opciones de instalar el sistema predefinido para un entorno de escritorio normal."), "\n", React.createElement(_components.p, null, "Una vez hecho esto ya tenia mi pantallita negra dispuesta a usarse. Como para Debian estable ya tengo el otro éste va a ser un poco mas inestable. Así que a poner repositorios nuevos:"), "\n", React.createElement(_components.p, null, "Accedemos como root:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">su</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Abrimos con el editor de texto nano el archivo de los repositorios de apt:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">nano /etc/apt/sources.list</code>"
    }
  })), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre class=\"language-text\"><code class=\"language-text\">#Unstable deb http://http.us.debian.org/debian unstable main non-free contrib #deb http://non-us.debian.org/debian-non-US unstable/non-US main contrib non-free deb-src http://http.us.debian.org/debian unstable main contrib non-free deb http://security.debian.org unstable/updates main contrib non-free\n\n#Experimental deb http://http.us.debian.org/debian experimental main non-free contrib deb-src http://http.us.debian.org/debian experimental main non-free contrib deb http://security.debian.org experimental/updates main contrib non-free</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Actualizamos apt:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">aptitude update</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y actualizamos nuestro sistema:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">aptitude dist-upgrade</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Continuaré escribiendo como estoy adecentando el sistema, pero os doy un adelanto de por donde va el asunto: kde4 y los mínimos programas."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
